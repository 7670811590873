/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

@media only screen and (max-width: 730px) {
  #logs-view {
    background: #5d9bc7;
  }
}

#logs-view {
  color: white;
  font-family: 'Open Sans', sans-serif;
  overflow: auto;
}

#logs-view h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}

#logs-view .title-bar {
  text-align: center;
  line-height: 9.6rem;
  height: 9.6rem;
}

.logs-header {
  margin: 0 auto;
  display: inline-block;
  padding: 0;
  padding-left: 6.8rem;
  font-size: 1.8rem;
  background-size: 3.2rem;
  background-repeat: no-repeat;
  background-position: 2rem;
  background-image: url('/images/logs-icon.svg');
}

.logs-log-container {
  position: relative;
  padding: 0;
  width: 100%;
  overflow: hidden;
}

.logs-log-container:last-child {
  padding-bottom: 9.6rem;
}

.logs-log-container-solo-view {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 9.6rem 0;
  width: 100%;
  height: calc(100% - 9.6rem * 2);
  overflow: hidden;
}

.logs-log-info {
  margin: 2rem;
  text-decoration: none;
}

.logs-log-icon {
  display: inline-block;
  height: 2.4rem;
  width: 2.4rem;
  border: 0.1rem solid white;
  border-radius: 1.2rem;
  background-size: 1.2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ffffff64;
  position: relative;
  top: 0.7rem;
  margin-right: 0.5rem;
}

.logs-log-name {
  font-size: 1.6rem;
  display: inline;
  color: white;
}

.logs-log-week-dropdown {
  position: absolute;
  right: 2rem;
  top: -0.3rem;
  width: 9rem;
  height: 2.6rem;
  background-color: #5288af;
}

.logs-log-container-solo-view .logs-log-week-dropdown {
  top: 8.3rem;
  right: 9.6rem;
}

.create-log-button {
  bottom: 2rem;
  right: 2rem;
  background-image: url('/images/add.svg');
}

.create-log-button.hidden {
  display: none;
}

#create-log-back-button {
  background-color: transparent;
}

.logs-graph-label {
  fill: white;
  stroke: none;
}

.logs-graph-axes {
  stroke: white;
  stroke-width: 0.2rem;
  fill: none;
}

.logs-graph-line {
  stroke: white;
  fill: none;
}

.logs-graph-tick {
  stroke: rgba(255, 255, 255, 0.8);
  fill: none;
}

.logs-graph-tick-big {
  stroke: white;
  stroke-width: 0.2rem;
}

.logs-graph-fill {
  fill: rgba(255, 255, 255, 0.4);
  stroke: none;
}

.logs-graph-line,
.logs-graph-fill {
  transition: transform 0.2s;
  transform: translate(0, 0) scale(1);
}

.logs-scroll-bar {
  fill: #80b1d3;
  stroke: none;
}

.logs-scroll-control {
  fill: #c6dceb;
  stroke: none;
  cursor: grab;
}

.logs-scroll-button {
  fill: white;
  stroke: white;
  stroke-linejoin: round;
  stroke-width: 0.6rem;
  cursor: pointer;
}

.logs-graph-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.1rem;
  line-height: 0.5rem;
  background: rgba(93, 155, 199, 0.9);
  pointer-events: none;
}

.logs-graph-point-highlight {
  fill: white;
  stroke: none;
}

.logs-graph-progress,
.logs-graph-selection-highlight {
  fill: rgba(255, 255, 255, 0.1);
  stroke: none;
}

#create-log-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #597285;
  max-width: 64rem;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1.618fr;
  grid-gap: 0.8rem 1.6rem;
  padding: 1.6rem;
  justify-content: center;
  align-content: center;
  font-size: 1.5rem;
}

.create-log-label {
  align-self: center;
  text-align: right;
}

input[type='number'].create-log-retention-duration-number {
  width: 5rem;
  height: 3rem;
}

.create-log-retention-duration-unit {
  width: 9rem;
}

#create-log-save-button {
  height: 4rem;
  max-width: 9rem;
  font-size: 1.5rem;
  background-color: #7f93a1;
  margin: 1.25rem 0;
}

#create-log-save-button:hover {
  background-color: #97aebf;
}

#create-log-save-button:active {
  background-color: #d2d9de;
}

#create-log-save-button:disabled,
#create-log-save-button:disabled:hover {
  background-color: #8dbee0;
}

.create-log-retention-duration {
  max-width: 4rem;
  text-align: center;
}

.create-log-device,
.create-log-property {
  width: 100%;
  margin-right: 1rem;
  max-width: 24rem;
}

.create-log-hint {
  text-align: center;
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.create-log-hint.hidden {
  display: none;
}

#log-remove-dialog {
  text-align: center;
}

#log-remove-back-button {
  background: transparent;
}

.log-remove-container {
  text-align: center;
  position: relative;
  top: calc(50% - 3.8rem);
  transform: translateY(-50%);
}

#logs-back-button.hidden {
  display: none;
}
