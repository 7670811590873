.group {
  background: #5288af;
  border-radius: 5px;
  border: 1px solid #48779a;
  margin: 12px 0;
  min-width: 250px;
  min-height: 50px;
  max-height: 32px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  overflow: inherit;
}

.group.open {
  max-height: 100000px;
}

.group .thing {
  opacity: 1;
}

.group:not(.open) .thing {
  opacity: 0;
}

.group div.bar {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  padding: 5px 10px;
  position: relative;
}

.group div.bar div.title {
  color: white;
}

.group div.bar .leftcontainer {
  display: flex;
}

.group div.bar button.edit {
  border: none;
  position: absolute;
  top: 5px;
  right: 1rem;
  width: 25px;
  border-radius: 50%;
  height: 25px;
  background: no-repeat center/100% url('/images/overflow.svg');
}

.group div.bar button.foldIn {
  background-image: url('/images/right-arrow.png');
  transform: rotate(90deg);
  background-size: 15px 15px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 25px;
  margin-right: 5px;
  background-color: transparent;
  height: 25px;
  transition: transform 0.3s;
  cursor: grab;
}

.group.open div.bar button.foldIn {
  transform: rotate(-90deg);
}

.group.open.drag-target {
  border: 0.2rem dashed white;
}

.drag-before::before {
  content: '';
  position: absolute;
  border-top: 0.2rem dashed white;
  width: 100%;
  top: -14px;
  left: 0;
}

.drag-after::after {
  content: '';
  position: absolute;
  border-top: 0.2rem dashed white;
  width: 100%;
  bottom: -14px;
  left: 0;
}

.group-overflow-menu {
  position: absolute;
  top: 4.5rem;
  right: 0.3rem;
  background-color: #48779a;
  color: white;
  padding: 1rem 0;
  border-radius: 0.5rem;
  transform: scale(1);
  transition: transform 0.25s ease;
  transform-origin: top right;
  z-index: 100;
  text-align: left;
}

.group-overflow-menu::after {
  content: '';
  position: absolute;
  top: -0.9rem;
  right: 1rem;
  border-width: 0 1rem 1rem;
  border-style: solid;
  border-color: #48779a transparent;
  display: block;
  width: 0;
}

.group-overflow-menu > a {
  color: #fff;
  font-size: 2rem;
  display: block;
  padding: 0.4rem 1rem;
  text-decoration: none;
}

.group-overflow-menu > a > img {
  height: 2rem;
  padding-right: 1rem;
  margin-bottom: -0.3rem;
}

.group-overflow-menu.hidden {
  transform: scale(0);
}

.group-overflow-menu > a:hover {
  background-color: #4d80a5;
}
