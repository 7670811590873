#rule-view {
  position: absolute;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

#rule-view.hidden {
  display: none;
}

#rule-view.selected {
  display: flex;
  flex-direction: column;
}

#rule-view > .title-bar {
  box-sizing: border-box;
  width: 100%;
  min-height: 9.6rem;
  padding: 0;
  padding-left: 9.6rem;
  display: flex;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  z-index: 10;
  background: #46769c;
}

#rule-view > .title-bar.invalid {
  background: #7f3939;
}

#delete-button {
  background: none;
  border: 0;
  width: 9.6rem;
  height: 9.6rem;
  position: fixed;
  top: 0;
  right: 0;
}

#delete-button:active {
  background: #777;
}

.rule-preview-button {
  top: 2rem;
  right: 2rem;
  background-image: url('/images/play.svg');
  display: none;
}

.rule-preview-button.stop {
  background-image: url('/images/stop.svg');
}

#rule-view .rule-info {
  padding: 0;
  flex: 1;
  height: 100%;
}

#rule-view .rule-info h1 {
  margin: 0;
  margin-top: 2rem;
  font-size: 1.6rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  color: white;
}

.rule-name-customize {
  width: 0.7em;
  height: 0.7em;
  padding: 0.2em 0.4em;
  padding-bottom: 0;
}

#rule-view .rule-info h1 .rule-name {
  padding: 0 1rem;
}

#drag-hint p {
  font-size: 1.8rem;
}

#rule-view .rule-info > p {
  font-size: 1.6rem;
  margin-top: 0.8rem;
  padding-left: 1rem;
  color: #dae3eb;
}

#rule-area {
  position: relative;
  background: url('/images/background.png');
  flex: 1;
}

#drag-hint,
#onboarding-hint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#drag-hint {
  display: none;
}

#rule-area.drag-location-hint > #drag-hint {
  display: flex;
}

#onboarding-hint {
  display: flex;
}

.drag-hint {
  flex: 1;
  position: relative;
}

.drag-hint-trigger {
  background: rgba(129, 184, 225, 0.7);
}

.drag-hint-effect {
  background: rgba(255, 207, 138, 0.7);
}

.drag-hint-block {
  background: none;
  border: 2px dashed black;
  text-align: center;
  line-height: 16rem;
  font-size: 4rem;
  margin: 1.4rem auto;
}

#rule-parts-list-container {
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 2px rgba(0, 0, 0, 0.24);
  z-index: 10;
  background: #5a9ac9;
  position: relative;
}

#rule-parts-list {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin: 0 3rem;
}

/* stylelint-disable no-descending-specificity */
#rule-parts-list p {
  padding: 0 1.4rem;
}
/* stylelint-enable no-descending-specificity */

#rule-parts-list .rule-part-block {
  display: block;
  margin-bottom: 0.4rem;
}

.rule-part {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  width: 12.8rem;
}

.rule-part-container {
  position: absolute;
  border-radius: 1rem;
  font-size: 0;
}

.rule-part-container.open {
  z-index: 15;
}

/* stylelint-disable no-descending-specificity */
.rule-part-container > .rule-part-block {
  margin: 0;
  border-radius: 1rem 0 0 1rem;
}
/* stylelint-enable no-descending-specificity */

.rule-part-info {
  width: 20rem;
  background: white;
  border-radius: 0 1rem 1rem 0;
  display: inline-block;
  vertical-align: bottom;
}

.rule-part-name {
  color: #4a4a4a;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  margin: 0 2rem;
  height: 5rem;
  line-height: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.property-select-option,
.message-input-container {
  background: #4a4a4a;
  height: 5rem;
  line-height: 5rem;
  color: white;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
  display: none;
  cursor: default;
}

.property-select-name,
.message-input-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
}

.message-input-label {
  overflow: visible;
}

.property-select-option.selected,
.property-select-option.open-button,
.message-input-container {
  position: relative;
  border-radius: 0 0 1rem 0;
}

.property-select.open {
  transform: translate(0, 0);
  z-index: 20;
  max-height: 20rem;
  overflow: auto;
}

.property-select-option.selected::after,
.property-select-option.open-button::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 1rem solid white;
  border-right: 1rem solid transparent;
  border-left: 1rem solid transparent;
  transform: translate(0.1rem, 3.5rem) rotate(135deg);
}

.property-select-option:hover {
  background: #9b9b9b;
}

.property-select.open > .property-select-option.selected::after {
  display: none;
}

.property-select.open > .property-select-option,
.property-select-option.selected,
.property-select-option.open-button,
.message-input-container {
  display: flex;
  align-items: center;
}

.property-select.open > .property-select-option.selected {
  border-radius: 0;
}

.property-select-option select,
.property-select-option input,
.message-input-container input {
  margin-left: 0.6rem;
}

.property-select-option input:not([type]),
.property-select-option input[type=''],
.property-select-option input[type='text'],
.message-input-container input:not([type]),
.message-input-container input[type=''],
.message-input-container input[type='text'] {
  width: 7rem;
}

.property-select-option input[type='number'],
.property-select-option select {
  width: 4rem;
  background: #4a4a4a;
  color: white;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid white;
  font-size: 1.6rem;
}

.property-select-option select {
  width: auto;
  flex: 1;
}

.property-select-option input[type='number'] {
  padding: 0.2rem;
}

.property-select.open > .property-select-option.open-button {
  display: none;
}

.dragging {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 20;
}

#delete-area.delete-active {
  display: block;
}

#delete-area {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  box-sizing: border-box;
  margin: 0;
  padding: 2rem;
}

.delete-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px dashed #4a4a4a;
  box-sizing: border-box;
}

.delete-prompt,
.drag-hint-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  color: black;
}

#rule-delete-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  background: rgba(64, 64, 64, 0.8);
}

#rule-view .rule-delete-dialog {
  display: block;
  width: 48rem;
}

#rule-view .rule-delete-dialog > p {
  font-size: 2rem;
}

#rule-delete-overlay.active {
  display: block;
}

#connection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#connection.hidden {
  display: none;
}

#connection > path {
  stroke: #305067;
  stroke-width: 0.5rem;
  fill: none;
}

#connection > circle {
  stroke: white;
  stroke-width: 0.2rem;
  fill: #305067;
}

#onboarding-hint h2 {
  color: #4a4a4a;
  font-weight: normal;
  font-style: italic;
}

#onboarding-hint.hidden {
  display: none;
}

@media only screen and (max-width: 700px) and (min-height: 400px) {
  #drag-hint {
    flex-direction: column;
  }
}

#rule-parts-list-scroll-left,
#rule-parts-list-scroll-right {
  position: absolute;
  top: 0;
  width: 3.6rem;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  background: #46769c;
}

#rule-parts-list-scroll-left {
  left: 0;
}

#rule-parts-list-scroll-right {
  right: 0;
}

#rule-parts-list-scroll-left:active,
#rule-parts-list-scroll-right:active {
  background: #81b8e1;
}

#rule-parts-list-scroll-left.hidden,
#rule-parts-list-scroll-right.hidden {
  display: none;
}

.scroll-icon {
  width: 3.2rem;
  height: 3.2rem;
}

.time-input {
  background: #4a4a4a;
  height: 5rem;
  line-height: 5rem;
  color: white;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
  border-radius: 0 0 1rem 0;
  border: 0;
}

.rule-part-block.trigger.inactive,
.rule-part-block.effect.inactive {
  background: #888;
}

#connection > path.active {
  stroke: #5dbbff;
}

#connection > circle.active {
  fill: #5dbbff;
}

.triangle-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: none;
  color: white;
  border: 0;
  font-size: 1.8rem;
}

.triangle-select-container {
  position: relative;
  display: inline-block;
  border-bottom: 2px solid white;
}

.triangle-select-container::after {
  border-top: 0.6rem solid white;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  content: '';
  position: absolute;
  transform: translate(-50%, 0.2rem);
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
}
