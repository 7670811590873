@media only screen and (max-width: 730px) {
  #rules-view,
  #rules {
    background: #5d9bc7;
  }
}

#rules-view {
  color: white;
  font-family: 'Open Sans', sans-serif;
  overflow: auto;
}

.rule-info h3 {
  margin: 0;
  font-size: 1.6rem;
}

#rules-view h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}

#rules-view .title-bar {
  text-align: center;
  line-height: 9.6rem;
  height: 9.6rem;
}

.rules-header {
  margin: 0 auto;
  display: inline-block;
  padding: 0;
  padding-left: 6.8rem;
  font-size: 1.8rem;
  background-size: 3.2rem;
  background-repeat: no-repeat;
  background-position: 2rem;
  background-image: url('/images/rules-icon.png');
}

.rule {
  position: relative;
  background: #46769c;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 40rem;
  height: 26.6rem;
  border-radius: 0.5rem;
  display: inline-block;
  vertical-align: bottom;
}

.rule.invalid {
  background: #7f3939;
}

.rule-preview {
  border-radius: 0.5rem 0.5rem 0 0;
  background: url('/images/background-small.png');
  box-sizing: border-box;
  padding: 3rem;
  width: 100%;
  height: 60%;
}

.rule-edit-overlay {
  border-radius: 0.5rem 0.5rem 0 0;
  position: absolute;
  width: 100%;
  height: 60%;
  opacity: 0;
  background: rgba(64, 64, 64, 0.8);
}

.rule-edit-overlay:hover {
  opacity: 1;
}

.rule-info {
  padding: 1rem 2rem;
  width: 66%;
  display: inline-block;
  vertical-align: bottom;
  height: 30%;
  overflow: hidden;
}

.rule-switch {
  padding-right: 2rem;
  padding-top: 11%;
  float: right;
  display: inline-block;
  vertical-align: bottom;
}

.rule-switch > .switch-slider {
  width: 5.5rem;
  height: 2.2rem;
  border-radius: 1.1rem;
  background: #4a4a4a;
}

.rule-switch > .switch-slider::after {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background: #b1b1b3;
  transform: translate(0, -0.4rem);
}

.rule-switch > input:checked + .switch-slider {
  background: #305067;
}

.rule.invalid > .rule-switch > input:checked + .switch-slider {
  background: #4d2121;
}

.rule-switch > input:checked + .switch-slider::after {
  background: white;
  transform: translate(2.5rem, -0.4rem);
}

#create-rule {
  border: 1px dashed #979797;
  text-align: center;
  cursor: pointer;
}

#create-rule h3 {
  padding-top: calc(26.6rem / 2 - 2.5em);
}

.rule-delete-button {
  position: absolute;
  top: -1.8rem;
  right: -1.8rem;
  background: url('/images/delete-button.png');
  background-size: 3.6rem;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  border: 1px solid #979797;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
}

.rule-edit-overlay.delete {
  height: 100%;
  z-index: 10;
  opacity: 1;
}

.rule-edit-overlay.delete .rule-edit-button,
.rule-edit-overlay.delete .rule-delete-button {
  display: none;
}

.rule-edit-overlay.delete .rule-delete-dialog {
  display: block;
}

#rules-view .rule-delete-dialog > p {
  font-size: 2rem;
}

#rules-view .rule-part-block {
  width: 8.2rem;
  height: 8.2rem;
}

#rules-view .rule-part-icon {
  margin: 1.7rem;
  padding: 0;
}

#create-rule-button {
  bottom: 2rem;
  right: 2rem;
  background-image: url('/images/add.svg');
}

#create-rule-hint {
  text-align: center;
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#create-rule-hint.hidden {
  display: none;
}

#rules {
  display: grid;
  grid-template-columns: repeat(auto-fill, 40rem);
  grid-gap: 2rem;
  min-width: 40rem;
  justify-content: center;
  padding-bottom: 9.6rem;
}
