/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
@font-face {
  font-family: 'Zilla Slab';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/ZillaSlab-Regular.woff2') format('woff2'),
    url('/fonts/ZillaSlab-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Zilla Slab';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/ZillaSlab-Bold.woff2') format('woff2'),
    url('/fonts/ZillaSlab-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Zilla Slab';
  font-weight: 400;
  font-style: italic;
  src: url('/fonts/ZillaSlab-RegularItalic.woff2') format('woff2'),
    url('/fonts/ZillaSlab-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Zilla Slab';
  font-weight: 700;
  font-style: italic;
  src: url('/fonts/ZillaSlab-BoldItalic.woff2') format('woff2'),
    url('/fonts/ZillaSlab-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/opensans-regular.woff2') format('woff2'),
    url('/fonts/opensans-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/opensans-bold.woff2') format('woff2'),
    url('/fonts/opensans-bold.woff') format('woff');
}

html {
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  padding: 2rem;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}

body {
  padding: 0;
  background-color: #5d9bc7;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

section {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 32rem;
  min-height: 32rem;
}

section.selected {
  display: block;
}

input:not([type]),
input[type=''],
input[type='number'],
input[type='text'],
input[type='password'],
input[type='email'] {
  height: 1.75rem;
  background-color: #d2d9de;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  font-size: 1.6rem;
}

.text-button {
  background-color: #597285;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  color: #fff;
}

.text-button-settings {
  background-color: #597285;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  color: #fff;
}

.text-button::-moz-focus-inner {
  border: 0;
}

.text-button:hover,
.text-button:active {
  background-color: #658196;
}

.text-button:disabled,
.text-button:disabled:hover {
  background-color: #597285;
  opacity: 0.5;
}

.icon-button {
  position: fixed;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 2.8rem;
  padding: 0;
  background-color: #5d9bc7;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  z-index: 100;
  cursor: default;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
}

.icon-button::-moz-focus-inner {
  border: 0;
}

.icon-button:hover,
.icon-button:active {
  opacity: 1;
}

.icon-button.hidden {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Zilla Slab', 'Open Sans', sans-serif;
}

#wordmark {
  position: fixed;
  bottom: 2rem;
  left: 2rem;
  height: 3.6rem;
}

#menu-wordmark {
  position: fixed;
  width: 20rem;
  top: 4rem;
  left: 9.5rem;
}

#update-message-area {
  position: fixed;
  top: 2rem;
  width: 33%;
  left: calc(33% - 1rem);
  background-color: rgba(76, 124, 160, 0.6);
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  color: white;
  font-size: 2rem;
  transform: translateY(0);
  transition: transform 0.25s ease;
  z-index: 100;
}

#update-message-area-text {
  padding-bottom: 2rem;
}

#update-message-area > button {
  height: 4rem;
  background-color: #48779a;
  font-size: 1.5rem;
  margin: auto 0.5rem;
}

#update-message-area > button:hover,
#update-message-area > button:active {
  background-color: #658196;
}

#update-message-area-reload {
  margin-right: 1rem;
}

#update-message-area.hidden {
  transform: translateY(-100%) translateY(-4rem);
}

#message-area {
  position: fixed;
  bottom: 3rem;
  width: 33%;
  left: calc(33% - 1rem);
  background-color: rgba(76, 124, 160, 0.6);
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  color: white;
  font-size: 2rem;
  transform: translateY(0);
  transition: transform 0.25s ease;
  z-index: 100;
}

#message-area.hidden {
  transform: translateY(100%) translateY(4rem);
}

#message-area.disconnected {
  background-color: #374956;
  z-index: 10001;
  color: #ccc;
}

@media only screen and (max-width: 730px) {
  #update-message-area,
  #message-area {
    width: calc(100% - 10rem);
    left: 3rem;
  }
}

#message-area > a:link,
#message-area > a:visited,
#message-area > a:hover,
#message-area > a:active {
  color: white;
  display: block;
  width: 100%;
  height: 100%;
}

#menu-button,
#back-button {
  top: 2rem;
  left: 2rem;
  z-index: 0;
}

#menu-button.menu-shown {
  z-index: 1000;
}

#menu-button {
  background: no-repeat center/100% url('/images/menu.svg');
}

#back-button {
  background: no-repeat center/100% url('/images/back.png');
}

#overflow-button {
  bottom: 2rem;
  right: 2rem;
  background: no-repeat center/100% url('/images/overflow.svg');
}

#overflow-menu {
  position: fixed;
  bottom: 9rem;
  right: 3rem;
  background-color: #5288af;
  color: white;
  padding: 1rem 0;
  border-radius: 0.5rem;
  transform: scale(1);
  transition: transform 0.25s ease;
  transform-origin: bottom right;
}

#overflow-menu::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  right: 1rem;
  border-width: 1rem 1rem 0;
  border-style: solid;
  border-color: #5288af transparent;
  display: block;
  width: 0;
}

#overflow-menu.hidden {
  transform: scale(0);
}

#overflow-menu > a:link,
#overflow-menu > a:visited,
#overflow-menu > a:hover,
#overflow-menu > a:active {
  color: #fff;
  font-size: 2rem;
  display: block;
  padding: 0.4rem 1rem;
  text-decoration: none;
}

#overflow-menu > a:hover {
  background-color: #4d80a5;
}

#overflow-menu > a:active {
  background-color: #48779a;
}

#overflow-menu > a > img {
  height: 2rem;
  padding-right: 1rem;
  margin-bottom: -0.3rem;
}

/* Dialog */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #305067;
  font-size: 1.6rem;
  color: #fff;
  overflow: auto;
}

.dialog.hidden {
  display: none;
}

.back-button {
  top: 2rem;
  left: 2rem;
  background: no-repeat center/100% url('/images/back.png');
}

#extension-back-button {
  background-color: transparent;
}

.switch-checkbox {
  display: none;
}

.switch-slider {
  display: block;
  width: 5.5rem;
  height: 2.2rem;
  border-radius: 1.1rem;
  background: #5d9bc7;
  transition: 0.1s;
}

.switch-slider::after {
  display: block;
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: white;
  transform: translate(0.35rem, 0.35rem);
  transition: 0.1s;
}

input:checked + .switch-slider::after {
  transform: translate(3.65rem, 0.35rem);
}

body.hidden {
  visibility: hidden;
}

@keyframes show-scrim {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes hide-scrim {
  from {
    opacity: 0.5;
    width: 100%;
  }

  to {
    opacity: 0;
    width: 100%;
  }
}

#connectivity-scrim {
  background-color: rgba(0, 0, 0);
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  animation: show-scrim 0.25s ease 0s;
  z-index: 1000;
}

#connectivity-scrim.hidden {
  animation: hide-scrim 0.25s ease 0s;
  width: 0%;
}

.arrow-select {
  display: inline-block;
  text-align: left;
  color: #fff;
  background-color: #7f93a1;
  margin: 1rem 0;
  font-size: 1.5rem;
  width: 50%;
  border: none;
  border-radius: 0.5rem;
  height: 4rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('/images/select-arrow.svg');
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position: calc(100% - 1rem);
  padding-left: 1rem;
}

.media-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.media-modal {
  width: calc(100% - 15.2rem);
  height: calc(100% - 15.2rem);
  position: absolute;
  top: 7.6rem;
  left: 7.6rem;
  text-align: center;
  z-index: 50;
}

.media-modal-frame {
  width: calc(100% - 3.2rem);
  height: calc(100% - 3.2rem);
  margin: 1.6rem;
}

.media-modal-content,
.media-modal-error,
.media-modal-image,
.media-modal-video {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.media-modal-content {
  background-color: #5288af;
  border-radius: 0.5rem;
  width: calc(100% - 6.4rem);
  box-sizing: border-box;
  height: calc(100% - 3.2rem);
  margin: 1.6rem;
  padding: 2rem;
  overflow: auto;
}

.media-modal-content p {
  font-size: 1.8rem;
  color: #fff;
}

.media-modal-close {
  box-sizing: border-box;
  display: block;
  position: absolute;
  right: 0;
  z-index: 10;
  background-color: #89b6d6;
  background-size: 1.6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/close.svg');
  border-radius: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
}

.media-modal-refresh {
  box-sizing: border-box;
  display: block;
  position: absolute;
  z-index: 10;
  background-color: #89b6d6;
  background-size: 1.6rem;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/refresh.svg');
  border-radius: 1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
}

.media-modal-error {
  display: inline-block;
  font-size: 2rem;
  padding: 4rem;
  color: white;
  background-color: #5288af;
}
