#rules-view h1,
#rule-view h1 {
  font-size: 3.2rem;
}

#rules-view h3,
#rule-view h3 {
  font-size: 1.8rem;
}

#rules-view p,
#rule-view p {
  font-size: 1.2rem;
}

.rule-part-block {
  border-radius: 1rem;
  margin: 1.4rem;
  padding: 0;
  width: 10rem;
  height: 10rem;
  background: #accce3;
  float: left;
}

.rule-part-block.trigger {
  background: #81b8e1;
}

.rule-part-block.effect {
  background: #ffcf8a;
}

.rule-part-icon {
  margin: 2.7rem;
  padding: 0;
  width: 4.8rem;
  height: 4.8rem;
}

.rule-edit-button,
.rule-delete-cancel-button,
.rule-delete-confirm-button {
  background: #ededed;
  border: none;
  border-radius: 0.3rem;
  color: #404040;
  padding: 1.2rem 2.4rem;
  font-size: 2rem;
}

.rule-edit-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rule-edit-button:active,
.rule-delete-cancel-button:active {
  background: rgba(237, 237, 237, 0.7);
}

.rule-delete-confirm-button {
  background: rgb(215, 0, 34);
  color: white;
  float: right;
}

.rule-delete-confirm-button:active {
  background: rgba(215, 0, 34, 0.7);
}

.rule-delete-dialog {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 75%;
}
